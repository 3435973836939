.recharge-tip-95{
  animation: recharge-tip-zoom-95 ease-in-out 1s infinite;
}

.recharge-tip-90{
  animation: recharge-tip-zoom-90 ease-in-out 1s infinite;

}

@keyframes recharge-tip-zoom-95 {
  0%{
    transform: scale(1);
  }

  50%{
    transform: scale(0.95);
  }

  100%{
    transform: scale(1);
  }
}

@keyframes recharge-tip-zoom-90 {
  0%{
    transform: scale(1);
  }

  50%{
    transform: scale(0.9);
  }

  100%{
    transform: scale(1);
  }
}